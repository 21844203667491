import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image";

import Head from "../components/head"

import Layout from "../components/layout"

import blogPostsStyles from "../styles/modules/blogposts.module.scss"

export const query = graphql`
    query($slug: String!) {
        allWpPost(filter: { slug: { eq: $slug } }) {
          nodes {
            title
            slug
            content
            featuredImage {
                node {
                    localFile{
                        childImageSharp {
                            fluid(maxHeight: 1180) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                    }
                  sourceUrl
                  title
                }
              }
          }
        }
      }
    `

const Blog = ( props ) => {
    const { data } = props
    const post = data.allWpPost.nodes[0]

    useEffect(() => {
        const installGoogleAds = () => {
            const elem = document.createElement("script");
            elem.src =
              "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            elem.async = true;
            elem.defer = true;
            document.body.insertBefore(elem, document.body.firstChild);
            elem.onload = () => {
                try {
                    const adsbygoogle = window.adsbygoogle || []
                    adsbygoogle.push({})
                } catch (e) {
                    console.error(e)
                }
            };
          };
          installGoogleAds();

    }, [])

    return (
        <Layout css_class="post">
            <Head title={post.title} />
            <main>
                <div className="container">
                    <section>
                        <div className="row">
                            <div className="col">
                                <h1>{post.title}</h1>
                                <Img fluid={post.featuredImage.node.localFile.childImageSharp.fluid} key={post.featuredImage.node.localFile.childImageSharp.fluid.src} />
                                <ins className="adsbygoogle"
                         style={{"display":"block"}}
                         data-ad-client="ca-pub-1435869001586096"
                         data-ad-slot="7917509468"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            post.content,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <ins className="adsbygoogle"
                         style={{"display":"block"}}
                         data-ad-client="ca-pub-1435869001586096"
                         data-ad-slot="7917509468"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
        </Layout>
    )
}

export default Blog
